<template>
  <div class="main-full-content edit_top_off_height detail_eidt">
    <div class="fixed_top" ref="fixedTopRef">

      <div class="steps">
        <el-steps :space="110" :active="Number(orderStatus)" simple finish-status="success">
          <div id="mouse" v-if="true" class="version_total"></div>
          <el-step :title="i.typeName" v-for="(i,d) in stepList" :key="d" :status="i.status">
           
            <template slot="icon">{{ d + 1 }}</template>
          </el-step>
        </el-steps>
      </div>
      <div ref="getheight" class="customer_info">
        <el-tooltip v-if="order.payTypeDetail" class="item" effect="dark" :content="order.payTypeDetail"
                    placement="top-start">
          <div style="color: red;">{{ order && order.payType ? '' : '付款方式：' }}{{ order.payType }}</div>
        </el-tooltip>
        <div v-else>
          <div style="color: red;">{{ order && order.payType ? '' : '付款方式：' }}{{ order.payType }}</div>
        </div>
        <el-tooltip class="item" effect="dark" :content="order.customer" placement="top-start">
          <div>客户名：
            <div class="a_ellipsis_box" style="display: inline-flex;">
              <div class="a_ellipsis" @click.prevent="$intoCustomerEditPage(order.cusId)" style="color:#57a3f3;">
                {{ order.customer }}
              </div>
            </div>
          </div>
        </el-tooltip>

        <div>订单号：{{ order.orderNo }}</div>
        <div>预报件数：{{ order.ctn }}</div>
        <div>
          <!-- （订单预报体积/订单实际体积 - 1）* 100%，结果的绝对值大于10%，标红 -->
          <el-popover placement="top" trigger="hover" width="3501/" content="">
            <span>{{ '（订单预报重量/订单过磅重 - 1）* 100%: ' + ((Number(order.kg) / Number(order.realKg) - 1) * 100).toFixed(3) + '%' }}</span>

            <i class="el-icon-question" slot="reference">预报重量：
              <span style="color: red;font-weight:bolder" v-if="Math.abs((Number(order.kg) / Number(order.realKg) - 1)) >= 0.03  "
              >{{ order.kg  }}</span>
              <span v-else>{{ order.kg  }}</span>
            </i>
          </el-popover>

        </div>
        <div>
          <!-- （订单预报体积/订单实际体积 - 1）* 100%，结果的绝对值大于10%，标红 -->
          <el-popover placement="top" trigger="hover" width="350" content="">
            <span>{{ '（订单预报体积/订单实际体积 - 1）* 100%: ' + ((Number(order.cmb) / Number(order.realCmb) - 1) * 100).toFixed(3) + '%' }}</span>

            <i class="el-icon-question" slot="reference">预报体积：
              <span style="color: red;font-weight:bolder" v-if="Math.abs((Number(order.cmb) / Number(order.realCmb) - 1)) >= 0.03  "
              >{{ order.cmb  }}</span>
              <span v-else>{{ order.cmb  }}</span>
            </i>
          </el-popover>

        </div>
        <div>订单类型：{{ order.containerType === '0' ? 'LCL拼柜' : 'FCL整柜' }}</div>
        <div v-if="checkIsCt()" class="font_weight">渠道名称：<span style="color: red">{{
            order.transportStatus_dictText
          }}</span></div>
        <div class="font_weight">
          报关方式：<span style="color: red">{{ order.declareType_dictText }}</span>
          退税份数：<span style="color: red">{{ order.blNumber }}</span>
        </div>
        <div v-if="checkIsCt()" class="font_weight">是否包税：<span style="color: red">{{
            getStrIsOrNot(order.hasTaxStr)
          }}</span></div>
        <div>附加费：{{ order.fjf }}</div>
        <div>实际件数：{{ order.realCtn }}</div>
        <div>过磅重：{{ order.realKg }}</div>
        <div>实际体积：{{ order.realCmb }}</div>

        <div class="font_weight" style="color: #67C23A">
          <el-popover placement="top" trigger="hover" width="777" content="">
            <p>
              计费数量说明：<br>
              入仓方式/派送方式/是否包税/计费单位/计费数量<br>
              人工或人工+设备/卡派/包税/KG/总实重（过磅重）、 总体积重 两者取大作为订单计费数量<br>
              人工或人工+设备/卡派/不包税/CBM/总实重（过磅重）除以对应国家体重比例计算出的体积 、 总实体 两者取大作为订单计费数量<br>
              人工或人工+设备/快递派或混派//KG/总实重（过磅重）、总体积重、对应国家快递单箱最低重*实件计算出的快递最低标准重，三个数值取大者作为订单计费数量<br>
              设备/卡派/包税/KG/总实重、总体积重 两者取大作为订单计费数量<br>
              设备/卡派/不包税/CBM/总实重除以各国体重比例计算出的体积、 总实体 两者取大作为订单计费数量<br>
              设备/快递派或混派/KG/每行的实重/体积重/对应国家快递单箱最低重 三个数值取大者，作为对应行的单箱计费重，总单箱计费重作为订单计费数量。<br>
            </p>

            <i class="el-icon-question" slot="reference">计费数量：{{ order.countFeeNum }}</i>
          </el-popover>

        </div>

        <div class="font_weight">派送国家：<span style="color: red">{{ order.countryRuleAddress }}</span></div>

        <div>拼柜仓库：{{ order.lclPlace_dictText }}</div>

        <div>交货仓库：{{ order.deliveryPoint_dictText }}</div>

        <div class="font_weight">到仓时间：<span style="color: red">{{ order.cargoArrivedStart }}</span></div>

        <div v-if="order.insurance==='1'"><span style="color: red">投保金额：{{
            order.insuranceFee
          }}，币种：{{ order.insuranceCur }}</span></div>
        <div v-else><span style="color: red">投保金额：，币种：</span></div>

        <div class="font_weight">入仓费是否记账：<span
            style="color: red">{{ getStrIsOrNot(order.isRucang) }}</span></div>

        <div>交货方式：{{ order.whType === '0' ? '自送货' : (order.whType === '1' ? '上门提货' : '') }}</div>

        <div class="font_weight">渠道最低消费：{{ order.minCharge }}</div>

        <div>收款人：{{ order.billman }}</div>

        <el-tooltip class="item" effect="dark" :content="order.remark" placement="top-start">
          <div>内部备注：
            <div class="a_ellipsis_box" style="display: inline-flex;">
              <div class="a_ellipsis" style="color:#57a3f3;">{{ subStrTenWei(order.remark) }}
              </div>
            </div>
          </div>
        </el-tooltip>
        <div v-if="checkIsCt()" class="font_weight"
             v-show="(order&&order.shipmentCountry === '美国' || order.shipmentCountry === '加拿大' || order.shipmentContinent ==='OC') && order.containerType === '0' && order.solicitation !=='1' ">
          锁价服务(美/加/澳):<span
            style="color: red">{{ getStrIsOrNot(order.lockChayan) }}</span></div>
          <div v-if="checkIsCt()" class="font_weight"
             v-show="(order&&order.shipmentCountry === '美国' || order.shipmentCountry === '加拿大') && order.containerType === '0' && order.solicitation !=='1'  ">
          销售(美/加):<span
            style="color: red">
            {{ order.saleLockPrice === '1' ? '是' : '否' }}
            </span></div>
          <div v-show="checkIsCtCopy()" class="font_weight"
           >
            销售三锁(欧洲):<span
           style="color: red">
           {{ order.saleLockPrice === '1' ? '是' : '否' }}
           </span></div>
        <!-- 销售锁价服务(美/加): -->
        <div v-if="checkIsCt()" class="font_weight"
             v-show="order&&order.shipmentContinent === 'EU' && order.containerType === '0' && order.solicitation !=='1'  ">
          欧洲三锁：<span
            style="color: red">{{ getStrIsOrNot(order.sanLock) }}</span></div>

        <div>产品行数：<span style="color:red">{{ order.goodsCount }}</span></div>

        <div>税号个数：<span style="color:red">{{ order.hscodeCount }}</span></div>
        <div>清关分类个数：<span style="color:red">{{ order.qgCount }}</span></div>


        <div v-if="buttonShowList['是否有权查看排柜周期']">排柜周期：
          <span style="color: #00a0e9"
                @click="$intoOperationCabinetEditPage(order.containerId)">{{ order.week }}
          </span>
        </div>

        <el-tooltip class="item" effect="dark" :content="order.pgRemark" placement="top-start">
          <div>排柜备注：
            <div class="a_ellipsis_box" style="display: inline-flex;">
              <div class="a_ellipsis" style="color:#57a3f3;">{{ subStrTenWei(order.pgRemark) }}
              </div>
            </div>
          </div>
        </el-tooltip>


        <div>订单是否锁定：{{ order.isLockOrder === '0' ? '否' : (order.isLockOrder === '1' ? '是' : '') }}</div>
        <div v-if="checkIsCt()">是否已自动录入关税：{{
            order.isGenerateGuanshui === '0' ? '否' : (order.isGenerateGuanshui === '1' ? '是' : '')
          }}
        </div>
        <div :style="order.isWarning==='0' ? 'color: #67C23A' : 'color: red'">
          问题件状态：{{ order.isWarning !== '1' ? '非问题件' : (order.isWarning === '1' ? '是问题件' : '') }}
        </div>
         <el-popover placement="top" trigger="hover" width="400" content="" style="min-width: 200px;"  v-if="order.isNeedShowMinNum === '1'">
            <p>
              当前国家快递单箱最低计费重为 {{order.coefficientNum}}KG，不足 {{order.coefficientNum}}KG 按 {{order.coefficientNum}}KG 计费
            </p>

            <i class="el-icon-question" slot="reference" style="font-weight:bold">此订单最低消：{{ order.minConsumptionNum }}KG</i>
          </el-popover>

<!-- {{order.sensitiveType.join()}} -->
<el-tooltip placement="top" v-show="order.sensitiveType">
  <div slot="content" >
    <div>提醒处理方案：</div>
    <div v-for="(item,index) in order.dealMethod" >
        <span>{{index+=1}}:{{item}}</span>
    </div>
     <div>执行类别：</div>
 <div v-for="(executeItem,executeIndex) in order.executeType" >
        <span>{{executeIndex+1}}:{{executeItem}}</span>
    </div>
    </div>
 <div >订单产品定义：<span>{{order.sensitiveType?order.sensitiveType.join():''}}</span></div>
</el-tooltip>
      
        <div></div>
        <!-- <div></div> -->
        <!-- <div></div> -->
        <!-- <div></div> -->
        <el-tooltip class="item" effect="dark" :content="order.storehouseRemark" placement="top-start">
          <div>仓库备注：
            <div class="a_ellipsis_box" style="display: inline-flex;">
              <div class="a_ellipsis" style="color:#57a3f3;">{{ subStrTenWei(order.storehouseRemark) }}
              </div>
            </div>
          </div>
        </el-tooltip>

      </div>


    </div>

    <el-tabs
        class="fixed_main"
        tab-position="top"
        @tab-click="handleClick"
        v-model="clickLabel"
        :style="{ '--fixedTopPosition': (fixedTopHeight + navHeight.headerTopBox + navHeight.headerTwoBox) + 'px', 'padding': '0 10px', 'margin-top':  fixedTopHeight + 'px'}"
    >
      <el-tab-pane
          v-for="(tab, index) in tabs"
          :key="tab.component"
          :label="tab.label"
          :name="tab.label"
          :lazy="true"
      >
        <component :is="tab.component"
                   v-if="tab.show"
                   :key="tab.component"
                   :order="order"
                   :orderStatusFlag="order.orderStatus"
                   :countFeeNum="order.countFeeNum"
                   :containerType="order.containerType"
                   :solicitation="order.solicitation"
                   :is-show="onlyShow"
                   :stepMap="stepMap"
                   @ok="initVue"
                   @init="initVue"
                   @getBasicFrom="getBasicFrom">
        </component>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import DocPageBasicForm from "@/views/docpage/modules/DocPageBasicForm";
import FileModule from "@/views/docpage/modules/FileModule";
import StatusModule from "@/views/docpage/modules/StatusModule";
import LogModule from "@/views/docpage/modules/LogModule";
import {getAction} from "@/api/manage";
import PayModule from "@/views/docpage/modules/PayModule";
import RecModule from "@/views/docpage/modules/RecModule";
import CostModule from "@/views/docpage/modules/CostModule";
import VolumeModule from "@/views/docpage/modules/VolumeModule";
import ISFModule2 from "@/views/docpage/modules/ISFModule2";
import HBlModule from "@/views/docpage/modules/HBlModule";
import {setRouteQuery} from '@/utils/util';
import LogNoModule from "@/views/docpage/modules/LogNoModule";
import KaidanWarehouseLogModule from "@/views/docpage/modules/KaidanWarehouseLogModule.vue";
import {mapState} from 'vuex'

export default {
  name: "Edit",
  components: {
    DocPageBasicForm,
    FileModule,
    StatusModule,
    LogModule,
    PayModule,
    RecModule,
    CostModule,
    HBlModule,
    VolumeModule,
    ISFModule2,
    // LalaModule,
    LogNoModule,
    KaidanWarehouseLogModule
  },
  data() {
    return {
      //默认第一个tab
      shipmentCountry: '',
      onlyShow: false,
      originTabs: [
        {label: "基本信息", component: "DocPageBasicForm", show: true},
        {label: "文件信息", component: "FileModule", show: false},
        {label: "材积信息", component: "VolumeModule", show: false},
        {label: "应收账单", component: "RecModule", show: false},
        {label: "销售结算", component: "CostModule", show: false},
        {label: "应付账单", component: "PayModule", show: false},
        {label: "物流信息", component: "StatusModule", show: false},

        {label: "操作记录", component: "LogModule", show: false},
        {label: "快递单号", component: "LogNoModule", show: true},
        {label: "HBL提单", component: "HBlModule", show: false},
        {label: "ISF", component: "ISFModule2", show: false},
        {label: "开单记录", component: "KaidanWarehouseLogModule", show: false},
      ],
      tabs: [
        {label: "基本信息", component: "DocPageBasicForm", show: true},
        {label: "文件信息", component: "FileModule", show: false},
        {label: "材积信息", component: "VolumeModule", show: false},
        {label: "应收账单", component: "RecModule", show: false},
        {label: "销售结算", component: "CostModule", show: false},
        {label: "应付账单", component: "PayModule", show: false},
        {label: "物流信息", component: "StatusModule", show: false},

        {label: "操作记录", component: "LogModule", show: false},
        {label: "快递单号", component: "LogNoModule", show: true},
        {label: "HBL提单", component: "HBlModule", show: false},
        {label: "ISF", component: "ISFModule2", show: false},
        {label: "开单记录", component: "KaidanWarehouseLogModule", show: false},
      ],
      tabs2: [
        {label: "基本信息", component: "DocPageBasicForm", show: true},

        {label: "文件信息", component: "FileModule", show: false},
        {label: "材积信息", component: "VolumeModule", show: false},
        {label: "应收账单", component: "RecModule", show: false},
        {label: "销售结算", component: "CostModule", show: false},
        {label: "物流信息", component: "StatusModule", show: false},

        {label: "操作记录", component: "LogModule", show: false},
        {label: "快递单号", component: "LogNoModule", show: true},
        {label: "HBL提单", component: "HBlModule", show: false},
        {label: "ISF", component: "ISFModule2", show: false},
        {label: "开单记录", component: "KaidanWarehouseLogModule", show: false},
      ],
      // tabs3: [
      //     {label: "基本信息", component: "DocPageBasicForm", show: true},
      //     {label: "文件信息", component: "FileModule", show: false},
      //     {label: "材积信息", component: "VolumeModule", show: false},
      //     {label: "应收账单", component: "RecModule", show: false},
      //     {label: "应付账单", component: "PayModule", show: false},
      //     {label: "物流信息", component: "StatusModule", show: false},
      //
      //     {label: "操作记录", component: "LogModule", show: false},
      //     {label: "HBL提单", component: "HBlModule", show: false},
      //     {label: "ISF", component: "ISFModule2", show: false},
      //     {label: "开单记录", component: "KaidanWarehouseLogModule", show: false},
      // ],
      clickLabel: '基本信息', //选中的tab
      url: {
        getDocPageTopInfo: '/interface/ttOrder/getDocPageTopInfo',
      },
      //是否显示
      order: {},
      orderStatus: '',
      stepList: [],
      stepMap: {},
      fixedTopHeight: '87', //自动计算顶部固定位置的高度
      basicFrom: {},
      queryCount:1,
      timeFlag:""
    };
  },
  watch: {
    '$route': {
      deep: true,
      handler(to, from) {
        let clickIndex = 0;
        if (to.path !== '/docpage/Edit') {
          // console.log(to,from,'跳转的地址不是/docpage/Edit');
          return;
        }
        console.log("路由变化，校验是否有权限查看订单");
        this.checkCanSeeTtOrder();
        setRouteQuery(to.query.orderNo, to.fullPath);
        this.initVue("basic", true);
        // 如果 isCost = 1 默认显示销售结算
        if (this.$route.query.cost && this.$route.query.cost === '1') {
          console.log("给我跳到销售结算");
          this.clickLabel = '销售结算'
        }
        if (this.$route.query.logNoSearch && this.$route.query.logNoSearch == '1') {
          console.log("给我跳到快递单号");
          this.clickLabel = '快递单号'
        }
        console.log("到底跳不跳",this.clickLabel);
        this.tabs.forEach((item, index) => {
          item.show = false;
          if (this.clickLabel && this.clickLabel === item.label) {
            clickIndex = index
          }
        })
        setTimeout(() => {
          this.tabs[clickIndex].show = true;
        }, 50);
      }
    }
  },
  computed: {
    ...mapState(["navHeight"]),
    buttonShowList() {
      return {
        '是否可以查看应付页面': this.$btnIsShow('docpage/modules/BasicForm', '0', '是否可以查看应付页面'),
        '是否有权查看排柜周期': this.$btnIsShow('docpage/Edit','0','是否有权查看排柜周期'),
        '已装柜可修改信息': this.$btnIsShow('isystem/RoleManage', '0', '已装柜可修改信息'),
        '已装柜有清关信息修改产品权限': this.$btnIsShow('isystem/RoleManage', '0', '已装柜有清关信息修改产品权限'),
      }
    }
  },
  methods: {
    getStrIsOrNot(str) {
      if (str === '1') {
        return '是';
      } else if (str === '0') {
        return '否';
      }
      return '';
    },
    //字符串 截取十位
    subStrTenWei(str) {
      if (str && str.length > 10) {
        return str.substring(0, 10) + "...";
      }
      return str;
    },
    shifouxianshiTab(tab) {
      let label = tab.label;
      let show = tab.show;
      let orderType = this.order.orderType;
      if (show) {
        if (orderType === '1' && ['销售结算', '快递单号'].indexOf(label) !== -1) {
          return false;
        } else {
          return true;
        }
      }
      console.log("是否传统", tab, show, label, orderType);
      return show;
    },
    // 判断 是否是传统 进行对应字段的隐藏
    checkIsCt() {
      if (this.order.orderType === '1') {
        return false;
      } else {
        return true;
      }
    },
    checkCanSeeTtOrder() {
      let that = this;
      let orderNo = this.$route.query.orderNo;
      if (orderNo && orderNo.length > 0) {
        getAction('/interface/ttOrder/checkCanSeeTtOrder', {orderNo: orderNo})
            .then(res => {

              if (res.success) {

              } else {
                this.$message({type: 'warning', message: res.message + "，两秒后返回原页面"});
                setTimeout(() => {
                  that.$router.go(-1);
                }, 2000)

              }
            }).catch(err => {

          console.log("校验订单权限报错", err)
          this.$message({type: 'warning', message: err.message + "，两秒后返回原页面"});
          setTimeout(() => {
            that.$router.go(-1);
          }, 2000)
        })
      }
    },


    handleClick(tab) {
      // console.log('点击选中的tab',tab);
      this.tabs.map(t => {
        if (t.label !== tab.label) {
          t.show = false;
        } else {
          t.show = true;
        }
      })
    },
    initStep() {
      if (this.$refs['step']) {
        this.$refs['step'].initVue();
      }
    },
    getUserIsSales() {
      let flag = this.buttonShowList['是否可以查看应付页面'];
      console.log('是否可以查看应付页面', flag);
      if (flag) {
        return true;
      } else {
        // this.tabs = JSON.parse(JSON.stringify(this.tabs2));
        console.log("tabs", this.tabs)
        return false;
      }

    },
    listToMap(list, name) {
      var map = {};
      for (var index in list) {
        map[list[index][name]] = list[index];
      }
      return map;
    },
    // isClear是否清空order字段，默认不清空
    initVue(tab, isClear=false) {
      //页面一加载完成，根据订单号获取数据 渲染在头部
      let orderNo = this.$route.query.orderNo;
      this.queryCount = this.queryCount +1;
      this.stepMap = {};
      isClear && (this.order = {});
      // console.log("订单号", orderNo);
      if (orderNo) {
        getAction(this.url.getDocPageTopInfo, {orderNo: orderNo,tab:tab,queryCount:this.queryCount}).then((res) => {
          // console.log('客服页头部的数据',res.result);
          if (res.result) {
            this.order = res.result.osOrder;
            let ct = this.checkIsCt();
            let exclueds = [];
            if (!this.buttonShowList['是否可以查看应付页面']) {
              exclueds.push('应付账单');
            }
            if (!ct) {
              exclueds.push('销售结算');
              exclueds.push('快递单号');
            }
            if (exclueds && exclueds.length > 0) {
              this.tabs = this.tabs.filter(tab => exclueds.indexOf(tab.label) === -1);
            } else {
              this.tabs = this.originTabs;
            }
            console.log("计算完权限的tabs", this.tabs);
            console.log('装柜时间', this.order.containerLoadStr)
            // console.log("获取的客服页订单父亲数据", this.order);
            this.stepList = res.result.processList;
            this.stepMap = this.listToMap(this.stepList, 'typeInt');
            //已开船之后 不能编辑
            if (Number(this.order.orderStatus) >= 18) {
              this.onlyShow = true;
            }
            //判断如果有这两个权限的人 就可以修改
            let flag = this.buttonShowList['已装柜可修改信息'];
            if (flag) {
              this.onlyShow = false;
            }
            let flag2 = this.buttonShowList['已装柜有清关信息修改产品权限'];
            if (flag2) {
              this.onlyShow = false;
            }
            this.shipmentCountry = "";
            this.shipmentCountry = this.order.countryRuleAddress
            this.$nextTick(() => {
              this.fixedTopHeight = this.$refs.fixedTopRef && this.$refs.fixedTopRef.offsetHeight;
            })
            setTimeout(() => {
              this.fixedTopHeight = this.$refs.fixedTopRef && this.$refs.fixedTopRef.offsetHeight;
            }, 1000);
          }

        });
      }
      // console.log(this.order,'order666');
    },
    getBasicFrom(form) {
      this.basicFrom = form;
      // console.log(this.basicFrom, 'this.basicFrom');
      let list = ["shipmentCountry","shipmentContinent","containerType","solicitation"];
      for (var name in list) {
        let val = this.basicFrom[name] || '';
        this.$set(this.order,name,val);
      }
    },
    isAfterApril2024(timestamp) {
 // 将时间戳转换为 Date 对象
    let date = new Date(timestamp);

    // 判断年份和月份
    if (date.getFullYear() < 2024 || (date.getFullYear() === 2024 && date.getMonth() < 3)) {
        return true;
    } else {
        return false;
    }
},
checkIsCtCopy(){
  if(this.order.createTime){
    // 是否为2024年04月之前的订单
    let timeFlag = this.isAfterApril2024(this.order.createTime)

    if(this.order&&this.order.isEurope == '1'&&timeFlag){
      return false
    }else if((this.order&&this.order.isEurope == '1') && this.order.containerType === '0' && this.order.solicitation !=='1'&&this.order.orderType !== '1'&&!timeFlag){
      return true
    }else {
      return false
    }
  }
}
  },
  created() {
    console.log("页面初始化，校验是否有权限查看订单")
    this.checkCanSeeTtOrder();
    // this.getUserIsSales();
    this.initVue();
    setRouteQuery(this.$route.query.orderNo, this.$route.fullPath);
  },
  mounted() {
    //窗口尺寸改变
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          this.fixedTopHeight = this.$refs.fixedTopRef && this.$refs.fixedTopRef.offsetHeight;
        })
      })();
    };
  },

};
</script>
<style lang="scss">
.edit_top_off_height {
  .fixed_main .el-tabs__header {
    top: var(--fixedTopPosition) !important;
  }

  // .el-tabs--top > .el-tabs__content {
  //     top: 0;
  // }
}

.detail_eidt {
  .el-radio {
    margin-right: 10px;
  }

  .el-button--text {
    padding: 5px 0px;
  }

  .el-step.is-simple:not(:last-of-type) .el-step__title {
    max-width: unset;
  }

  .el-steps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .el-step {
    margin-top: 10px;
  }

  .title_tab_text {
    font-size: 14px !important;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    position: relative;
    padding-left: 10px;
    display: flex;

    span {
      font-size: 14px !important;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .el-button {
      font-size: 12px !important;
      line-height: 12px !important;

      span {
        font-size: 12px !important;
        line-height: 12px !important;
      }
    }
  }

  .title_tab_text::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    background: #2d8cf0;
    left: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .el-tabs {
    background: #F0F0F0;
    padding-bottom: 62px !important;
    height: 100%;
    margin: 0 -12px;
  }

  // .el-tabs__content {
  //   background-color: #FFFFFF;
  //   height: 100%;
  //   padding: 12px;
  // }
  .el-tabs__header {
    margin: 0 -12px;
    padding: 0 22px;
    width: calc(100% - 146px);
  }

  .el-step__arrow {
    width: 20px !important;
    flex-grow: unset !important;
  }

  .el-steps--simple {
    padding: 0;
    background: #FFFFFF;
  }

  .el-step__title {
    white-space: nowrap;
    font-size: 14px !important;
  }

  .el-step.is-simple .el-step__arrow::before, .el-step.is-simple .el-step__arrow::after {
    width: 2px;
    height: 8px;
  }

  .el-step.is-simple .el-step__arrow::before {
    transform: rotate(-45deg) translateY(-2px)
  }

  .el-step.is-simple .el-step__arrow::after {
    transform: rotate(45deg) translateY(2px)
  }

  .el-step.is-simple .el-step__icon {
    width: 24px;
    height: 24px;
    font-size: 14px !important;
    font-weight: 400;
    border-color: #999999;
    color: #999999;
  }

  .el-step.is-simple .is-success .el-step__icon {
    border-color: #67C23A;
    color: #67C23A;

    .el-step__icon-inner {
      font-size: 16px !important;
    }
  }

  .el-step {
    flex-basis: unset !important;
  }

  .el-step__title.is-wait {
    color: #999999;
  }

  .f-p-b-15 {
    padding-bottom: 10px !important;

    .el-button {
      margin-bottom: 5px;
    }
  }

  .c-th-has-bgcolor {
    margin-top: 0px;
  }
}
</style>
<style lang="scss" scoped>
.detail_eidt {
  height: calc(100% - 113px);
  margin-top: -20px;

  .a_ellipsis {
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: unset !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .a_ellipsis_box {
    width: 90px;
    min-width: unset !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .fixed_top {
    margin: 0 -12px;
    width: calc(100% - 146px);
    padding: 0 12px;
    box-sizing: border-box;
  }

  .customer_info {
    padding-bottom: 4px;

    div {
      margin-bottom: 6px;
      min-width: 200px;
      margin-right: 5px;
    }
  }
}

.version_total {
  position: absolute;
  width: 10%;
  height: 5%;
}

.el-divider--horizontal {
  margin-top: 16px;
  margin-bottom: 20px;

  .el-divider__text {
    font-size: 20px;
  }
}

// .fixed {
//     position: fixed;
//     width: auto;
//     top: 8px;
//     left: 397px;
//     color: #000000;
//     height: 50px;
//     /* background-color: #ffffff; */
//     z-index: 1000;
//     font-size: 11px;
// }

// .fixed2 {
//     position: fixed;
//     width: 80%;
//     top: 22px;
//     left: 397px;
//     color: #000000;

//     /* background-color: #ffffff; */
//     z-index: 1000;
//     font-size: 11px;
// }
</style>

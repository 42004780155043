<template>
  <el-dialog
    title="发起提货"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
    >
      <!-- 提货地址信息-->
      <el-row class="flex_center" :gutter="30">
        <el-col :span="13" v-if="this.addTakeDeliveryInfoFlag">
          <el-form-item
                  prop="orderNos"
                  label="订单号"
                  :label-width="formLabelWidth"
                  key="contact"
          >
            <el-input
                    @blur="queryDeliveryAddress"
                    v-model="form.orderNos"
                    maxlength="50"
                    show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item
            prop="contactId"
            label="提货地址信息"
            key="contactId"
            :label-width="formLabelWidth"
          >
          <div style="display: flex;flex-direction: row;justify-content: space-between;">
            <f-select
                    v-model="form.contactId"
                    :is-need="form.contactId"
                    :data-list="selectSupplierList"
                    :clear-select = "clearSelect"
                    @changet="updateThinfo"
                    style="width: 82%"
            ></f-select>
            <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="addSupInfoDialog"
            >新增</el-button>
          </div>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="thSupplier"
            label="公司"
            :label-width="formLabelWidth"
            key="thSupplier"
          >
            <el-input
              v-model="form.thSupplier"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contact"
            label="联系人"
            :label-width="formLabelWidth"
            key="contact"
          >
            <el-input
              v-model="form.contact"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactTel"
            label="联系电话"
            :label-width="formLabelWidth"
            key="contactTel"
          >
            <el-input
              v-model="form.contactTel"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactAdd"
            label="地址"
            :label-width="formLabelWidth"
            key="contactAdd"
          >
            <el-input
              v-model="form.contactAdd"
              maxlength="255"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
              prop="isNeedSendTakeDriverPhone"
              label="提货司机信息短信推送"
              :label-width="formLabelWidth"
              v-if="!addTakeDeliveryInfoFlag"
              key="contactAdd"
          >
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'1'"
                @click.native.prevent="radioClick4('1')"
            >是</el-radio>
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'0'"
                @click.native.prevent="radioClick4('0')"
            >否</el-radio>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="getsTime"
            label="提货时间"
            :label-width="formLabelWidth"
            key="getsTime"
          >
            <el-date-picker
              id="getTakeTime"
              v-model="form.getsTime"
              type="datetime"
              :format="'yyyy-MM-dd HH:mm'"
              placeholder="选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isWeiban"
            key="isWeiban"
            label="是否需要尾板车"
            :label-width="formLabelWidth"
          >
            <el-select v-model="form.isWeiban" clearable filterable>
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isBanyun"
            key="isBanyun"
            label="是否需要搬运装车"
            :label-width="formLabelWidth"
          >
            <f-select
              v-model="form.isBanyun"
              :isNeed="form.isBanyun"
              :dict="'is_default'"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="palletNum"
            label="托盘数"
            :label-width="formLabelWidth"
            key="palletNum"
          >
            <el-input v-model="form.palletNum" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13" v-if="addTakeDeliveryInfoFlag">
          <el-form-item
                  prop="pcs"
                  label="pcs"
                  :label-width="formLabelWidth"
                  key="pcs"
          >
            <el-input v-model="form.pcs" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            key="remark"
          >
            <el-input
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table :data="selectOrderList" border show-summary max-height="200" v-if="!addTakeDeliveryInfoFlag">
        <el-table-column prop="orderNo" label="订单号">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ctn" label="箱数">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ctn"></el-input>
<!--            <span>{{ scope.row.ctn }}</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="kg" label="重量">
          <template slot-scope="scope">
            <el-input v-model="scope.row.kg"></el-input>
<!--            <span>{{ scope.row.kg }}</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="cmb" label="体积">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cmb"></el-input>
<!--            <span>{{ scope.row.cmb }}</span>-->
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="addTakeDeliveryInfo" v-if="addTakeDeliveryInfoFlag">确认</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit" v-else>确认</el-button>
    </span>
    <el-dialog title="添加联系人"
               v-if="supplierVisible"
               :visible.sync="supplierVisible" width="30%" center :append-to-body="true">

      <el-form :model="supplier" :rules="supplierRules" ref="supplier">
        <el-form-item label="供应商名" prop="name" :label-width="formLabelWidth" key="suppliername">
          <el-input v-model="supplier.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司名" prop="company" :label-width="formLabelWidth" key="suppliercompany">
          <el-input v-model="supplier.company" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact" :label-width="formLabelWidth" key="suppliercontact">
          <el-input v-model="supplier.contact" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="tel" :label-width="formLabelWidth" key="suppliertel">
          <el-input v-model="supplier.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country" :label-width="formLabelWidth" key="suppliercountry">
          <el-select
                  :loading="coloading"
                  @click.native="initCountryList"
                  v-model="supplier.country" placeholder="请选择" filterable clearable @change="initStateList">
            <el-option v-for="(item,index) in countrylist" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省" prop="state" :label-width="formLabelWidth" key="supplierstate">
          <el-select
                  :loading="stateloading"
                  v-model="supplier.state" placeholder="请选择" filterable clearable @change="initCityList">
            <el-option v-for="item in statelist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="city" :label-width="formLabelWidth" key="suppliercity">
          <el-select
                  :loading="cityloading"
                  v-model="supplier.city" placeholder="请选择" filterable clearable>
            <el-option v-for="item in citylist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address" :label-width="formLabelWidth" key="supplieraddress">
          <el-input v-model="supplier.address" autocomplete="off" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth" key="supplierremark">
          <el-input v-model="supplier.remark" autocomplete="off" type="textarea" show-word-limit
                    maxlength="255"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button type="success" plain @click="saveSupplier" :disabled="supplierDisable">确 定</el-button>
        <el-button type="success" plain @click="supplierVisible = false">取 消</el-button>
      </div>

    </el-dialog>


  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { validateCtn,validateCmb } from "../../../utils/validator";
import {getallcountries, getcityList, getstateList, httpAction} from "../../../api/manage";

export default {
  name: "addTakeDeliveryInfoDialog",
  props: {
    visible: {
      type: Boolean,
    },
    addTakeDeliveryInfoFlag: {
      type: Boolean,
    },
    selectedTrailerIdList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      coloading: false,
      cityloading: false,
      stateloading: false,
      countrylist: [],
      // 省基础数据
      statelist: [],
      // 市基础数据
      citylist: [],
      selectSupplierMap:{},
      supplier: {},
      supplierVisible:false,
      isEdit: false,
      form: {},
      formLabelWidth: "130px",
      selectSupplierList: [],
      clearSelect:true,
      selectOrderList: [],
      rules: {
        contactId: [
          { required: true, message: "请选择提货地址信息", trigger: "change" },
        ],
        getsTime: [
          { required: true, message: "请选择提货时间", trigger: "change" },
        ],
        isWeiban: [
          { required: true, message: "请选择是否需要尾板车", trigger: "change" },
        ],
        isBanyun: [
          { required: true, message: "请选择是否需要搬运装车", trigger: "change" },
        ],
        orderNos: [
          { required: true, message: "请输入订单号", trigger: "change" },
        ],
        palletNum: [{ validator: validateCtn, trigger: "change" }],
        pcs: [{validator: validateCtn, trigger: "change" }],
      },
      url: {
        //生成其他出库数据
        realSendTake: "/warehouse/takeDelivery/updateRealSendTake",
        getTakeWarehouseInfoByIds:
          "/warehouse/takeDelivery/getTakeWarehouseInfoByIds",
        getTakeWarehouseInfoByOrderNos:"/warehouse/takeDelivery/getTakeWarehouseInfoByOrderNos",
        getCusIdByOrderNos:"/warehouse/takeDelivery/getCusIdByOrderNos",
        addTakeDeliveryInfo: "/warehouse/takeDelivery/addTakeDeliveryInfo",
        addSupplier: '/customer/supplier/add',
      },
      getsTime: "",
    };
  },
  watch: {
    visible(val) {
      this.selectSupplierList = []
      if(!this.addTakeDeliveryInfoFlag){
        if (val && this.selectedTrailerIdList) {
          postAction(
                  this.url.getTakeWarehouseInfoByIds,
                  this.selectedTrailerIdList
          ).then((res) => {
            if (res && res.result) {
              let map = res.result;
              this.selectOrderList = map["orderList"];
              this.selectSupplierList = map["supplierList"];
              this.selectSupplierMap = map["supplierMap"];
              this.$set(this.form,'isWeiban',map['isWeiban']);
              this.$set(this.form,'isBanyun',map['isBanyun']);
              this.$set(this.form,'palletNum',map['oceanPkgs']);
              this.$set(this.form,'remark',map['remark']);
              this.$set(this.form,'getsTime',map['takeTime']);
              this.$set(this.form,'contactId',map['supplierId']);
              this.updateThinfo();
            }
          });
        }
      }else{
        this.queryDeliveryAddress()
      }
    },
  },
  methods: {
    // 初始化国家基础数据
    initCountryList() {
      this.coloading = true

      this.countrylist = []
      getallcountries().then(res => {
        if (res.success) {
          for (var i = 0; i < res.result.length; i++) {
            let obj = {}
            obj.value = res.result[i].cnName
            obj.label = res.result[i].twoCode + '-' + res.result[i].cnName
            this.countrylist.push(obj)
          }
          this.coloading = false
        }
      })

      this.coloading = false

    },
    // 初始化省数据
    initStateList(val) {
      this.stateloading = true
      this.statelist = []
      this.citylist = []
      if (val) {
        getstateList({cnName: val})
                .then((res) => {
                  if (res.success) {
                    for (var i = 0; i < res.result.records.length; i++) {
                      let obj = {}
                      obj.value = res.result.records[i].cname
                      obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                      this.statelist.push(obj)
                    }
                    this.stateloading = false
                  }
                })
      } else {
        this.stateloading = false
      }
    },
    // 初始化城市数据
    initCityList(val) {
      this.cityloading = true
      this.citylist = []
      if (val) {
        getcityList({cname: val})
                .then((res) => {
                  if (res.success) {
                    for (var i = 0; i < res.result.records.length; i++) {
                      let obj = {}
                      obj.value = res.result.records[i].cname
                      obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                      this.citylist.push(obj)
                    }
                    this.cityloading = false
                  }
                })
      } else {
        this.cityloading = false
      }
    },
    saveSupplier() {
      this.supplierDisable = true;
      let url = this.url.addSupplier;
      let method = "post";
      this.$refs['supplier'].validate((valid) => {
        if (valid) {

          httpAction(url, method, this.supplier)
                  .then(res => {
                    if (res.success) {
                      this.$message.success(res.message);
                      this.supplierVisible = false;
                      //重新获取供应商集合
                      this.queryDeliveryAddress()
                    } else {
                      this.supplierDisable = false;
                      this.$message.warning(res.message);
                    }

                  }).catch(err => {

            this.supplierDisable = false;
            this.$message.error(err.message);
          })
        } else {
          this.supplierDisable = false;
        }
      })
    },
    addSupInfoDialog(){
      if(this.form.orderNos){
        postAction(
                this.url.getCusIdByOrderNos,
                {orderNos:this.form.orderNos}
        ).then((res) => {
          if (res && res.result) {
            this.supplier.cusId = res.result.cusId
            this.supplierVisible = true;
          }
        })
        return;
      }
      this.$message.error("请输入订单号，再新增提货信息！")
    },
    queryDeliveryAddress() {
      let that = this
      if (this.addTakeDeliveryInfoFlag) {
        if(this.form.orderNos){
          postAction(
                  this.url.getTakeWarehouseInfoByOrderNos,
                  {orderNos:this.form.orderNos}
          ).then((res) => {
            if (res && res.result) {
              let map = res.result;
              this.selectSupplierList = map["supplierList"]
              this.selectSupplierMap = map["supplierMap"]

            }
          }).catch(()=>{
            this.selectSupplierList = []
          });
        }else{
          this.selectSupplierList = []
        }
        console.log(this.selectSupplierList)

      }
    },
    radioClick4(e){
      e === this.form.isNeedSendTakeDriverPhone? (this.form.isNeedSendTakeDriverPhone ='') :(this.form.isNeedSendTakeDriverPhone = e)
    },
    updateThinfo() {
      if (this.form.contactId) {
        console.log(this.selectSupplierMap)
        console.log(this.selectSupplierMap[this.form.contactId])
        let obj =
          this.selectSupplierMap && this.selectSupplierMap[this.form.contactId];
        if (obj) {
          this.$set(this.form, "thSupplier", obj.name);
          this.$set(this.form, "contact", obj.contact);
          this.$set(this.form, "contactTel", obj.tel);
          this.$set(this.form, "contactAdd", obj.address);
          this.$set(this.form, "isNeedSendTakeDriverPhone", obj.isNeedSendTakeDriverPhone);
        }
      }
    },
    addTakeDeliveryInfo(){

      if (!this.form.getsTime) {
        this.$message.warning("请选择提货时间");
        return false;
      }
      this.form.getsTime = this.$momonet(new Date(this.form.getsTime)).format(
              "yyyy-MM-DD HH:mm:ss"
      );
      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.addTakeDeliveryInfo, this.form).then((res) => {
            if(res.success){
              this.$message.success(res.message)
              this.handleClose();
              this.$emit("ok");
            }
          });
        }
      });
    },
    handleSubmit() {
      if (!this.form.getsTime) {
        this.$message.warning("请选择提货时间");
        return false;
      }
      this.form.getsTime = this.$momonet(new Date(this.form.getsTime)).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      this.form.trailerIds = this.selectedTrailerIdList;
      this.form.orderList = this.selectOrderList;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.realSendTake, this.form).then((res) => {
            this.$alert(
              `生成的用车编号是<br><span style="color: green">${res.message}</span>`,
              "提货结果",
              {
                dangerouslyUseHTMLString: true,
              }
            );
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.selectSupplierList = [];
      this.selectSupplierMap = {};
      this.addTakeDeliveryInfoFlag = false
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
}
.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>

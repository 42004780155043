<template>
    <el-card class="main-full-content box-card">
      <div style="word-break: break-all;">
        <div><span style="font-weight: bold">排柜备注：</span>{{order.pgRemark || '/'}} </div>
        <div><span style="font-weight: bold">仓库备注：</span>{{order.storehouseRemark || '/'}}</div>
      </div>
        <div class="f-p-b-15">
            <el-button
                @click="addFee"
                type="primary"
                size="medium"
                v-if="order.payLock === '0'"
            >新增一行
            </el-button>
            <el-button
                @click="copyFee"
                type="primary"
                size="medium"
                v-if="buttonShowList.fzddfy && order.payLock==='0' && order.isLockOrder === '0'"
            >复制订单费用
            </el-button>
            <el-button
                @click="lockBatch"
                type="primary"
                size="medium"
                v-if="buttonShowList.plsd"
                :disabled="selectionRows2.length===0"
            >批量锁定
            </el-button>
            <el-button
                @click="unlockBatch"
                type="primary"
                size="medium"
                v-if="buttonShowList.plsd"
                :disabled="selectionRows2.length===0"
            >批量解锁
            </el-button>
            <el-button
                @click="removeSettleBatch"
                type="primary"
                size="medium"
                v-if="buttonShowList.plsc"
                :disabled="selectionRows2.length===0"
            >批量删除
            </el-button>
            <el-button
                @click="lockOrder"
                type="primary"
                size="medium"
                v-if="buttonShowList.sdddyf && order.payLock==='0'"
            >锁定订单应付
            </el-button>
            <!--<el-button @click="unlockOrder" type="primary" size="medium"-->
                       <!--v-if="$btnIsShow('docpage/modules/PayModule','0','解锁订单应付') && order.payLock==='1'"-->
            <!--&gt;解锁订单应付-->
            <!--</el-button>-->
            <el-button
                @click="lockIsOrder"
                type="primary"
                size="medium"
                v-if="buttonShowList.sddd && order.isLockOrder==='0' && (order.containerType==='1' || order.solicitation==='1')"
            >锁定订单
            </el-button>
            <el-button
                @click="unLockIsOrder"
                type="primary"
                size="medium"
                v-if="buttonShowList.jsdd && order.isLockOrder==='1' && (order.containerType==='1' || order.solicitation==='1')">
                解锁订单
            </el-button>
            <el-button
                @click="addAttach"
                type="primary"
                size="medium"
                v-if="buttonShowList.tjfsfy"
            >添加附属费用</el-button>
            <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.downloadHyYfModel, '海运应付批量导入模板')"
                    v-if="buttonShowList.yfdrmbxz"
            >海运应付批量导入模板下载
            </el-button>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadSectionFileHy"
                    accept=".xls,.xlsx"
                    style="margin:0 10px;display:inline"
            >
                <el-button size="medium" type="primary"

                           v-if="buttonShowList.plyfdr"
                >批量海运应付导入
                </el-button>
            </el-upload>
            <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.downloadCommissionExcel, '批量导入成本/应收账单模板')"
                    v-if="buttonShowList.drmbxz"
            >批量应付导入成本/应收模板下载
            </el-button>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadSettleCommissionFile"
                    accept=".xls,.xlsx"
                    style="margin:0 10px;display:inline"
            >
                <el-button size="medium" type="primary"

                           v-if="buttonShowList.pldr"
                >批量导入应付费用到成本
                </el-button>
            </el-upload>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadSettleRecFile"
                    accept=".xls,.xlsx"
                    style="margin:0 10px;display:inline"
            >
                <el-button size="medium" type="primary"

                           v-if="buttonShowList.pldr"
                >批量导入应付费用到应收
                </el-button>
            </el-upload>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadSettleCommissionAndRecFile"
                    accept=".xls,.xlsx"
                    style="margin:0 10px;display:inline"
            >
                <el-button size="medium" type="primary"

                           v-if="buttonShowList.pldr"
                >批量导入应付费用到成本+应收
                </el-button>
            </el-upload>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadAllSettle"
                    accept=".xls,.xlsx"
                    style="margin:0 10px;display:inline"
            >
                <el-button size="medium" type="primary"
                           v-if="buttonShowList.pldryscbys"
                >批量导入应付+成本+应收
                </el-button>
            </el-upload>
               <el-button
                    type="primary"
                    size="medium"
                    @click="synchronizationCost"
                         :disabled="!selectionRows2.length"
                          v-if="buttonShowList.pltbcb"
            >批量同步到成本
            </el-button>
        </div>
        <el-dialog title="复制订单" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="copy" ref="copy" :rules="rules">
                <el-form-item
                        class="el_form_item"
                        label="订单号"
                        label-width="140px"
                        prop="orderNo"
                >
                    <f-select
                            v-model="copy.orderNo"
                            :isNeed="copy.orderNo"
                            :isSuo="true"
                            :dict="'os_order_no'"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        class="el_form_item"
                        label="结算对象"
                        label-width="140px"
                        prop="settleId"
                >
                    <f-select
                            v-model="copy.settleId"
                            :isNeed="copy.settleId"
                            :dict="'os_contact'"
                    ></f-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="copySubmit">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog
                title="同步到"
                :visible.sync="syncFeeDialogVisible"
                width="15%"
                center
        >
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="syncFeeByType(1)">应收</el-button>
                <el-button type="primary" @click="syncFeeByType(2)">成本</el-button>
                <el-button type="primary" @click="syncFeeByType(4)">应收+成本</el-button>
                <el-button type="primary" @click="syncFeeDialogVisible = false">取消</el-button>
            </span>
        </el-dialog>

        <el-form :model="form" ref="payref" :rules="rules">
            <el-table
                    :header-cell-style="{ 'text-align': 'center' }"
                    :cell-style="{padding:'0px',margin:'0px'}"
                    ref="goodtab"
                    :data="form.osOrderSettleList"
                    style="width: 99%"
                    class="customTable"
                    v-loading="goodsloading"
                    @selection-change="handleSelectionChange2"
                    @sort-change="handleTableChange"
                    :cell-class-name="changeCellStyle">
                <el-table-column
                        type="selection"
                        width="30">
                </el-table-column>
                <el-table-column
                        prop="action"
                        label="操作"
                        minWidth="120"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.isAttach ==='1' || order.payLock ==='0'">
                            <el-button type="text" @click="removeSettle(scope.$index,scope.row.feename)"
                                       :disabled="scope.row.locker ==='1' || ['1','2','9'].indexOf(scope.row.status) !== -1"
                            >删除
                            </el-button>
                            <el-button
                                type="text"
                                @click="unlock(scope.row)"
                                v-if="buttonShowList.js && scope.row.locker === '1' && ['1','2','9'].indexOf(scope.row.status) === -1"
                            >解锁
                            </el-button>
                            <el-button
                                type="text"
                                @click="lock(scope.row)"
                                v-if="buttonShowList.sd"
                            >锁定
                            </el-button>
                            <el-button
                                type="text"
                                @click="syncButton(scope.row)"
                                v-if="buttonShowList.tb"
                            >同步
                            </el-button>
                            <el-button
                                type="text"
                                @click="updateSettleAccountStatus(scope.row,'1')"
                                v-if="buttonShowList.gz && scope.row.isCloseAccount =='0'"
                            >
                                关账
                            </el-button>
                            <el-button
                                type="text"
                                @click="updateSettleAccountStatus(scope.row,'0')"
                                v-if="buttonShowList.jsgz && scope.row.isCloseAccount =='1' "
                            >
                                解除关账
                            </el-button>
                        </div>
                        <div v-if="order.payLock=='1'&& scope.row.isAttach ==='0'">
                            <el-form-item class="el_form_item">

                                <span style="color:red;">已锁定</span>
                            </el-form-item>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="locker"
                        label="锁定状态"
                        minWidth="60"
                >
                    <template slot-scope="scope">
                        <!--          <el-button type="text" @click="saveShip(scope.row)">保存</el-button>-->
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.feenameUs'">
                            <span>{{getTag(scope.row.locker)}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="settlement"
                        label="结算对象"
                        minWidth="150"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">结算对象</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item flex_form" :prop="'osOrderSettleList.' + scope.$index + '.settleId'"
                                      :rules="scope.row.preTotal?[]:rules.settlement">


                            <f-select :id="'copySettleId' + scope.$index" :data-list="settlementSelectedList" :dict-size="tableCount" @change="getSettle(scope)" v-model="scope.row.settleId" :is-need="scope.row.settleId"></f-select>
                            <i class="el-icon-document-copy icon_copy" @click="copyFuc(scope.$index)"></i>
                            <!--                            <f-select :disabled="getIsDisable(scope.row)" :dict="'hy_supplier_list'" v-model="scope.row.settleId" :is-need="scope.row.settleId" @changet="getSettlement"-->
                            <!--                                      @change="setSettlement(scope.$index,scope.row.settleId)"></f-select>-->
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column

                        prop="feename"
                        label="费用中文名"
                        minWidth="150"
                        :show-overflow-tooltip="true"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">费用中文名</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.feeId'"
                                      :rules="rules.feeId">

                              <f-select :data-list="hyFeeSelectedList" :dict-size="tableCount" @change="updateFeeType(scope.row,scope.$index)" v-model="scope.row.feeId" :is-need="scope.row.feeId"></f-select>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="feenameUs"
                        label="费用英文名"
                        minWidth="120"
                        show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.feenameUs'">
                            <span>{{scope.row.feenameUs}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="price"
                        label="价格"
                        minWidth="80"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">价格</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.price'"
                                      :rules="rules.price">

                            <el-input v-model="scope.row.price" type="number" @input="getSummaries2"
                                      :disabled="getIsDisable(scope.row)"></el-input>


                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="quantity"
                        label="数量"
                        minWidth="80"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">数量</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.quantity'"
                                      :rules="rules.qty">
                            <el-input v-model="scope.row.quantity" type="number" @input="getSummaries2"
                                      :disabled="getIsDisable(scope.row)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="total"
                        label="合计"
                        minWidth="100"
                >
                    <template slot-scope="scope">
                         <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.total'"
                         :rules="rules.price" :key="'osOrderSettleList.' + scope.$index + '.total'">
<!--                            <el-input v-model="scope.row.total" disabled></el-input>-->
                           <span>{{scope.row.total}}</span>
                        </el-form-item>

                    </template>
                </el-table-column>
              <el-table-column
                  v-if="buttonShowList.kygsj"
                  prop="prePrice"
                  key="prePrice"
                  label="预估单价"
                  minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.prePrice}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  key="preQty"
                  v-if="buttonShowList.kygsj"
                  prop="preQty"
                  label="预估数量"
                  minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.preQty}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  v-if="buttonShowList.kygsj"
                  prop="preTotal"
                  key="preTotal"
                  label="预估总额"
                  minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.preTotal}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="cha"
                  label="差异额(预估-实际)"
                  minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.preTotal >0 ?scope.row.preTotal-scope.row.total:''}}</span>
                </template>
              </el-table-column>
                <el-table-column
                        prop="moneyType"
                        label="交易币种"
                        min-width="100"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">交易币种</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.moneyType'"
                                      :rules="rules.moneyType">
                            <f-select
                                    v-model="scope.row.moneyType"
                                    :isNeed="scope.row.moneyType"
                                    :dict="'bd_cur'"
                                    @change="getSummaries2"
                                    :disabled="getIsDisable(scope.row)"></f-select>
                            <!-- @change="getSummaries2 && getRate(scope.row)" -->
                        </el-form-item>
                    </template>

                </el-table-column>
                <el-table-column
                        prop="realMoneyType"
                        label="折合币种"
                        minWidth="100"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.realMoneyType}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="exchangeRate"
                        label="汇率"
                        minWidth="80"
                >
                    <template slot-scope="scope">
                        <!-- <el-form-item class="el_form_item"
                                      :prop="'osOrderSettleList.' + scope.$index + '.exchangeRate'"> -->
                        <!--                                                      {{ getRate(scope.row)}}-->
                        <!-- <el-input v-model="scope.row.exchangeRate" disabled></el-input>
                    </el-form-item> -->
                        <span>{{scope.row.exchangeRate}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="exchangeTotal"
                        label="折合金额"
                        minWidth="100"
                >
                    <template slot-scope="scope">
                        <!-- <el-form-item class="el_form_item"
                                      :prop="'osOrderSettleList.' + scope.$index + '.exchangeTotal'"> -->
                        <!--                                                      {{// getChangeTotal(scope.row)}}-->
                        <!-- <el-input v-model="scope.row.exchangeTotal" disabled></el-input>
                    </el-form-item> -->
                        <span>{{scope.row.exchangeTotal}}</span>
                    </template>
                </el-table-column>
              <el-table-column
                  prop="invoiceNoThird"
                  label="发票号"
                  minWidth="100"
              >
                <template slot-scope="scope">
                  <el-form-item class="el_form_item"
                                :prop="'osOrderSettleList.' + scope.$index + '.invoiceNoThird'">
                    <el-input v-model="scope.row.invoiceNoThird" type="textarea"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
                <el-table-column
                        prop="remark"
                        label="备注"
                        minWidth="120"
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item f-p-5"
                                      :prop="'osOrderSettleList.' + scope.$index + '.remark'">
                            <el-input v-model="scope.row.remark" maxlength="250" type="textarea"
                                      :disabled="getIsDisable(scope.row)"
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="createBy"
                        label="录入人"
                        minWidth="100"
                        show-tooltip-when-overflow
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.createBy'">
                            <span class="span-ellipsis">{{parseDict(scope.row.createBy,'sys_create_by')}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="录入时间"
                        minWidth="100"
                        show-tooltip-when-overflow

                >
                    <template slot-scope="scope">
                        <span>
                            {{formateTime(scope.row.createTime)}}
                        </span>

                    </template>
                </el-table-column>
                <el-table-column
                        prop="lockTime"
                        label="锁单时间"
                        minWidth="100"
                        show-tooltip-when-overflow
                >
                    <template slot-scope="scope">
                        <span>{{formateTime(scope.row.lockTime)}}</span>
                    </template>
                </el-table-column>


                <el-table-column
                        prop="moneyWay"
                        label="收款方式"
                        minWidth="80"
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.moneyWay'">
                            <span v-text="scope.row.moneyWay"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="status"
                        label="是否销账"
                        minWidth="80"
                >
                    <template slot-scope="scope">
                        <!--                        0:未销帐  1:已销帐 2：部分销账  9:付款费用已申请-->
                        <!--                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.xzCode'">-->
                        <span v-if="scope.row.status && scope.row.status==='1'" style="color:green">已销账</span>
                        <span v-else-if="scope.row.status && scope.row.status==='2'" style="color:green">部分销账</span>
                        <span v-else-if="scope.row.status && scope.row.status==='9'" style="color:green">付款费用已申请</span>
                        <span v-else style="color:red">未销账</span>
                        <!--                        </el-form-item>-->
                    </template>
                </el-table-column>
                <el-table-column
                        prop="applyNo"
                        label="付款编码"
                        minWidth="80"
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.applyNo'">
                            <span v-text="scope.row.applyNo"></span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <!--                <el-table-column-->
                <!--                        prop="billTime"-->
                <!--                        label="付款时间"-->
                <!--                        minWidth="100"-->
                <!--                >-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.billTime'">-->
                <!--                            <span>{{formateTime(scope.row.billTime)}}</span>-->
                <!--                        </el-form-item>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column
                        prop="xzTime"
                        label="销账时间"
                        minWidth="100"
                >
                    <template slot-scope="scope">
                        <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.xzTime'">
                            <span>{{formateTime(scope.row.xzTime)}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="taxPosition"
                        label="关税位置"
                        minWidth="100"
                >
                    <template slot-scope="scope">
                        <!-- <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.taxPosition'">
                            <el-input v-model="scope.row.taxPosition" type="textarea" disabled></el-input>
                        </el-form-item> -->
                        <span>{{scope.row.taxPosition}}</span>
                    </template>
                </el-table-column>


            </el-table>
            <div class="flex_wrap_between">
                <div></div>
                <!-- {{ss}} -->
                <el-button class="margin_5" @click="updateBatch" type="primary" size="medium">
                    保存
                </el-button>
            </div>


        </el-form>
        <el-divider></el-divider>
        <el-col :span="9">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>代理应付统计</span>
              </div>
              <div class="text item">
                <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    height="450px"
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="payColumns"
                    :data="fenzuList"
                    :cell-style="{ 'text-align': 'center' }">
                </f-table>

              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="15">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>费用修改记录</span>
            </div>
            <div class="text item">
              <fee-log-module ref="fee" @ok="init" :type="'1'"></fee-log-module>
            </div>
          </el-card>

        </el-col>
      应收费用：
      <div v-if="receiveList && receiveList.length>0">
                  <span  v-for="(item,index) in receiveList" :key="index"
                         style="margin: 0 15px 0 0;font-size: 18px">{{ item.moneyType }}:{{ item.total }}</span>
      </div>

      <br>
      实际的应付费用：
      <div v-if="payList && payList.length>0">
            <span  v-for="(item,index) in payList" :key="index"
                   style="margin: 0 15px 0 0;font-size: 18px">{{item.moneyType}}:{{item.total}}</span>
      </div>

      <br>
      预估的应付费用：
      <div v-if="preList && preList.length>0">
            <span  v-for="(item,index) in preList" :key="index"
                   style="margin: 0 15px 0 0;font-size: 18px">{{ item.moneyType }}:{{ item.total }}</span>
      </div>

      <br>
      <div v-if="buttonShowList.klr">
        实际利润（折合成CNY）
        <span style="margin: 0 15px 0 0;font-size: 18px">
                    <span style="color: red;margin: 0 5px 0 0"></span>
                    &nbsp;&nbsp;CNY：{{profit}} <span style="color: red;margin: 0 0 0 3px"></span></span> &nbsp;&nbsp;
        <br>
        预估（折合成CNY）
        <span style="margin: 0 15px 0 0;font-size: 18px">
                    <span style="color: red;margin: 0 5px 0 0"></span>
                    &nbsp;&nbsp;CNY：{{preProfit}} <span style="color: green;margin: 0 0 0 3px"></span></span> &nbsp;
      </div>
      &nbsp;
    </el-card>
</template>

<script>
    import {deleteAction, getAction, putAction,postAction} from "@/api/manage";
    import FeeLogModule from "./FeeLogModule";
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {caculateShequ} from "@/utils/caculate";
    import {validateUnitValue, validateCtn, validateCmb} from "@/utils/validator";

    export default {
        name: "PayModule",
        mixins: [QueryMixins],
        components: {
            FeeLogModule,
        },
        props: {
            isShow: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
              payColumns: [
                {
                  prop: 'settlement',
                  label: '结算对象',
                  /*  sortable: 'settlement',*/
                },
                {
                  prop: 'total',
                  label: '金额',
                  /*  sortable: 'total',*/
                },
                {
                  prop: 'moneyType',
                  label: '币种',
                  /* sortable: 'moneyType',*/
                },
              ],
              fenzuList:[],
                dictCodes: ['sys_create_by'],
                allSettlelist: [],
                allFeeTypelist: [],
                order: {},
                btnLoading: false,
                formData2: {
                    pageIndex: 1,
                    pageSize: 100,
                },
                //GBP
                totalgbp: 0,
                // SGD
                totalsgd: 0,
                // USD
                totalusd: 0,
                // NCD
                totalncd: 0,
                // AUD
                totalaud: "",
                // GBP

                // VND
                totalvnd: "",
                // NZD
                totalnzd: "",
                // HKD
                totalhkd: "",
                // CAD
                totalcad: "",
                // EUR
                totaleur: "",
                // INR
                totalinr: "",
                // CNY
                totalcny: "",
                // KRW
                totalkrw: "",
                // NOK
                totalnok: "",
                // JPY
                totaljpy: "",
                // NZD
                ss: "",
                // TWD
                totaltwd: "",

                visible: false,
                row: {},
                feeTypeList: [],
                feeTypeMap: {},
                form: {osOrderSettleList: []},
                url: {
                    updateSettleAccountStatus: '/order/os/settle/updateSettleAccountStatus',
                    getSettleList: '/order/os/settle/list',
                    copy: '/order/os/settle/copyOrderFee',
                    delete: '/order/os/settle/delete',
                    deleteBatch: '/order/os/settle/deleteBatch',
                    lock: '/order/os/settle/lock',
                    unlock: '/order/os/settle/unlock',
                    lockBatch: '/order/os/settle/lockBatch',
                    lockOrder: '/order/os/settle/lockOrder',
                    lockIsOrder: '/order/os/settle/lockIsOrder',
                    unlockIsOrder: '/order/os/settle/unLockIsOrder',
                    unlockBatch: '/order/os/settle/unlockBatch',
                    unlockOrder: '/order/os/settle/unlockOrder',
                    updateBatch: '/order/os/settle/updateBatch',
                    syncFee2CommissionBySingle: '/finance/fee/settleCommission/syncFee2CommissionBySingle',
                    syncFee2CommissionByList: '/finance/fee/settleCommission/syncFee2CommissionByList',
                    manualsyncFee: '/finance/fee/order/manualsyncFee',

                    getCurRate: '/sys/cur/rate/getList',
                    downloadModel: '/order/os/settle/tax/in/downloadExcel',
                    importExcel: '/order/os/settle/tax/in/import',
                    importHySettles: "order/os/goods/importHySettles",
                    downloadHyYfModel: '/order/os/settle/Hy/in/downloadHyYfExcel',
                    downloadCommissionExcel: "/finance/fee/settleCommission/downloadCommissionExcel",
                    importCommission: '/finance/fee/settleCommission/importCommission',
                    importAllSettle: '/file/read/excel/importAllSettle',
                    batchManualsyncFee:"/finance/fee/order/batchManualsyncFee"
                },
                userlist: [],
                fbaloading: false,
                storeloading: false,
                warehouseList: [],
                fbalist: [],
                addresslist: [],
                addressloading: false,
                goodsloading: false,
                selectedRowKeys2: [],
                selectionRows2: [],
                //校验
                rules: {
                    settlement: [
                        {required: true, message: "请选择结算对象", trigger: "change"},
                    ],
                    feeId: [
                        {required: true, message: "请选择费用中文名", trigger: "change"},
                    ],
                    price: [
                        {required: true, message: "请输入价格", trigger: "blur"},
                         {validator: validateUnitValue, trigger: "change"},
                    ],

                    qty: [
                        {required: true, message: "请填写数量", trigger: "blur"},
                        {validator: validateUnitValue, trigger: "change"},
                    ],
                    moneyType: [
                        {required: true, message: "请选择币种", trigger: "change"},
                    ],
                    orderNo: [
                        {required: true, message: "请选择订单号", trigger: "change"},
                    ],
                    settleId: [
                        {required: true, message: "请选择结算对象", trigger: "change"},
                    ],
                    invoiceNoThird: [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                },
                copy: {},
                dialogFormVisible: false,
                syncFeeDialogVisible: false,
                isouri: false,
                cploading: false,
                proMap: {},
                prolist: [],
                coloading: false,
                sysCurRateList: [],
                curCacu: [],
                settleProfit: [],
                profit: '',
              preProfit: '',
                yszj: '',
                yfzj: '',
                settlement: '',
                receiveList: [],
                payList: [],
                preList: [],

                //结算对象集合
                settlementList:[],
                //结算对象下拉框
                settlementSelectedList:[],
                //费用对象 集合
                hyFeeList:[],
                //费用对象 下拉框
                hyFeeSelectedList:[],
                //费用个数
                tableCount:0,
                syncRow: null,

            }
        },
        methods: {
            // 更新订单费用的关账状态
            updateSettleAccountStatus(row,type){
                if(!row.id){
                    this.$message.warning('请保存数据');
                    return;
                }
                let form = {id:row.id,isCloseAccount:type,orderNo:row.orderNo,type:'0',feename:row.feename,orderType:'0',settleType:'1'}
                let title ="确定将费用" + row.feename + (type=='0'? "关账吗":"解除关账吗")
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.updateSettleAccountStatus,form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initBase()
                    })
                }).catch(() => {

                })

            },
            changeCellStyle (row, column, rowIndex, columnIndex) {
              // 应收-是否已销账添加背景色
              if(row.row.status && ['1', '2', '9'].indexOf(row.row.status) !== -1) {
                return 'table_green_bg'
              } else {
                return 'table_red_bg'
              }
            },
            setSettlement(index) {
                this.$set(this.form.osOrderSettleList[index], 'settlement', this.settlment);
                // console.log('变化的结算对象',this.form.osOrderSettleList[index]);

            },
            getSettlement(val) {
                // console.log('获取的结算对象',val);
                this.settlment = val;
            },

            copyFuc(index) {
              //获取input对象
              var input = document.getElementById('copySettleId'+index)
              //console.log(input,'input')
              //选中input标签
              input.select()
              //执行复制
              document.execCommand('copy')
              this.$message.success('复制成功!')
            },
            downloadModel(url, fileName) {
                let params = {...this.queryForm, ...this.filterForm}
                postAction(url, params, {responseType: 'blob'})
                    .then(response => {
                        if (!response) {
                            return
                        }
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName + '.xls')
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }

                    })
            },
            // 批量海运应付导入
            uploadSectionFileHy(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
              this.$confirm("如果出现相同的费用，请选择新增还是覆盖?", "提示", {
                confirmButtonText: "覆盖",
                cancelButtonText: "新增",
                distinguishCancelAndClose: true,
                type: "warning",
                closeOnClickModal: false,
              }).then(()=>{
                form.append("type","1");
                postAction(this.url.importHySettles, form)
                    .then((res) => {
                      this.btnLoading = true;
                      //这里做上传之后的操作
                      if (res.success) {
                        this.$message.success(res.message);
                        this.initSettleList();
                        this.init();
                      }

                    }).finally(() => {
                  this.btnLoading = false;
                })
              }).catch((action=>{
                if (action === 'cancel') {
                  form.append("type","2");
                  postAction(this.url.importHySettles, form)
                      .then((res) => {
                        this.btnLoading = true;
                        //这里做上传之后的操作
                        if (res.success) {
                          this.$message.success(res.message);
                          this.initSettleList();
                          this.init();
                        }

                      }).finally(() => {
                    this.btnLoading = false;
                  })
                } else {
                  this.btnLoading = false;
                }


              }))


            },
            getIsDisable(row) {
                //已分摊的费用不能修改不能变
                if (row.isAttach === '1') {
                    return false;
                } else if (this.order.payLock === '1' || row.locker === '1' || row.containerSettlementId) {
                    return true;
                } else {
                    return false;
                }


            },

            getChangeTotal(item) {
                if (!isNaN((parseFloat(item.total))) && !isNaN((parseFloat(item.exchangeRate)))) {
                    item.exchangeTotal = Math.round((parseFloat(item.total) * parseFloat(item.exchangeRate)) * 100) / 100;
                } else {
                    item.exchangeTotal = 0;
                }
            },
            getRate(row) {

                if (row.moneyType && row.realMoneyType) {
                    let obj = this.sysCurRateList.find(f => {
                        return f.amout == row.moneyType && f.amoutTo == row.realMoneyType;
                    });
                    console.log('有没有obj', obj);
                    if (obj) {
                        this.$set(row, 'exchangeRate', obj.rate);
                    } else {
                        this.$set(row, 'exchangeRate', '');
                    }
                }


            },
            init() {
                console.log('费用操作记录', this.$refs['fee']);
                if (this.$refs['fee']) {
                    this.$refs['fee'].initVue();
                }
            },
            handleClearSelection() {
                this.$refs["shiptab"].clearSelection();
            },
            handleClearSelection2() {
                this.$refs["goodtab"].clearSelection();
            },

            //导入产品数据

            getTag(item) {
                return item == "1" ? "已锁定" : "未锁定";
            },
            removeSettle(index, feename) {
                if ("1" === this.form.osOrderSettleList[index].isCloseAccount) {
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                let id = this.form.osOrderSettleList[index].id;
                //v-if="$btnIsShow('docpage/modules/PayModule','1','删除')"
                if (!id) {
                    this.form.osOrderSettleList.splice(index, 1);
                    return ture;
                } else {
                    if (!this.buttonShowList.sc) {
                        this.$message.warning('您暂时没有权限删除费用哦亲');
                        return false;
                    }
                    let containerSettlementId = this.form.osOrderSettleList[index].containerSettlementId;
                    if (containerSettlementId) {
                        this.$message.warning('柜子已分摊的费用不允许删除');
                        return false;
                    }
                    this.$confirm(`确定删除${feename || ''}吗`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "success",
                        closeOnClickModal: false,
                    })
                        .then(() => {
                            if (id && id.length > 1) {
                                deleteAction(this.url.delete, {id: id})
                                    .then(res => {
                                        this.$message.success(res.message);
                                        this.initSettleList();
                                        this.init();
                                    })
                            }
                        })
                }

            },
            removeSettleBatch() {
                let ids = this.selectionRows2.map(s => s.id).join(',')
                let isAllDelete = true;
                let warningMsg = '';
                let indexList = [];
                this.selectionRows2.forEach(s => {
                    if (!s.id) {
                        indexList.push(this.form.osOrderSettleList.indexOf(s));
                    }
                    let containerSettlementId = s.containerSettlementId;
                    if (containerSettlementId) {
                        warningMsg = '存在柜子已分摊的费用不允许删除';
                        isAllDelete = false;
                        return false;
                    } else if (['1', '2', '9'].indexOf(s.status) !== -1) {
                        warningMsg = '费用在财务流程中不允许删除';
                        isAllDelete = false
                        return false;
                    } else if (s.locker == '1') {
                        warningMsg = '已锁定的费用不允许删除';
                        isAllDelete = false
                        return false;
                    }else if (s.isCloseAccount == "1") {
                        warningMsg = "已关账的费用的费用不允许删除";
                        isAllDelete = false;
                        return false;
                    }
                })
                if (!isAllDelete) {
                    this.$message.warning(warningMsg);
                    return
                }

                this.$confirm(`确定删除吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        if (ids && ids.length > 1) {
                            deleteAction(this.url.deleteBatch + "?ids=" + ids)
                                .then(res => {
                                    this.$message.success(res.message);
                                    this.initSettleList();
                                    this.init();
                                })
                        }
                        if (indexList && indexList.length > 0) {
                            indexList.forEach((item, index) => {
                                this.form.osOrderSettleList.splice(item, 1);
                            })
                        }
                    }).catch(() => {

                })
            },
            copyFee() {
                this.copy = {};
                this.dialogFormVisible = true;
            },
            copySubmit() {
                this.$refs['copy'].validate(va => {
                    if (va) {
                        this.copy.type = '1';
                        this.copy.oldOrderNo = this.$route.query.orderNo;
                        putAction(this.url.copy + "?orderNo=" + this.copy.orderNo + "&type=1&oldOrderNo=" + this.copy.oldOrderNo + "&settleId=" + this.copy.settleId)
                            .then(res => {
                                this.$message.success(res.message);
                                this.dialogFormVisible = false;
                                this.initSettleList();
                                this.init();
                            })
                    }
                })
            },
            addFee() {
                this.form.osOrderSettleList.push({
                    settlement: '',
                    feename: '',
                    feenameUs: '',
                    price: '',
                    quantity: 1,
                    moneyType: 'CNY',
                    total: '',
                    remark: '',
                    withinRemark: '',
                    createBy: '',
                    createTime: '',
                    lockTime: '',
                    realMoneyType: '',
                    exchangeRate: '',
                    exchangeTotal: '',
                    moneyWay: '',
                    fkCode: '',
                    xzCode: '',
                    fkTime: '',
                    xzTime: '',
                    locker: '0'
                })
            },
            addAttach() {
                this.form.osOrderSettleList.push({
                    settlement: '',
                    feename: '',
                    feenameUs: '',
                    locker: '0',
                    price: '',
                    quantity: '',
                    moneyType: '',
                    total: '',
                    remark: '',
                    withinRemark:'',
                    createBy: '',
                    createTime: '',
                    lockTime: '',
                    realMoneyType: '',
                    exchangeRate: '',
                    exchangeTotal: '',
                    moneyWay: '',
                    fkCode: '',
                    xzCode: '',
                    fkTime: '',
                    xzTime: '',
                    isAttach: '1'
                })
            },
            formatDecimal(num, decimal) {
                num = num.toString()
                let index = num.indexOf('.')
                if (index !== -1) {
                    num = num.substring(0, decimal + index + 1)
                } else {
                    num = num.substring(0)
                }
                return parseFloat(num).toFixed(decimal)
            },
          getAllPrice(item) {
            // console.log('allPrice',(item.quantity * item.price).toFixed(3))
            if (!isNaN((parseFloat(item.quantity))) && !isNaN((parseFloat(item.price)))) {
              // item.total = this.formatDecimal( (item.quantity * item.price).toFixed(3),2 );
              item.total = caculateShequ(item.quantity,item.price);
            } else {
              item.total = 0;
            }
          },

            getAllPriceLiu2(item) {
                if (!isNaN((parseFloat(item.quantity))) && !isNaN((parseFloat(item.price)))) {
                    item.total = this.formatDecimal(parseFloat(item.quantity) * parseFloat(item.price), 2);
                } else {
                    item.total = 0;
                }
            },
            getAllPriceZero(row) {
                console.log('要计算的对象', row);


                if (!isNaN((parseFloat(row.quantity))) && !isNaN((parseFloat(row.price)))) {
                    let per = 0;
                    let total = parseInt(parseFloat(row.quantity) * parseFloat(row.price));
                    if (row.perferType === '数值') {
                        per = row.perferFee ? row.perferFee : 0;
                        row.total = parseInt(parseFloat(row.quantity) * parseFloat(row.price)) - Number(per);
                    } else if (row.perferType === '百分比') {

                        per = parseInt(parseFloat(total) * parseFloat(row.perferFee ? row.perferFee / 100 : 0));
                        if (parseFloat(total) > parseFloat(per)) {
                            row.total = parseInt(parseFloat(total) - parseFloat(per).toFixed(3));
                        } else {
                            row.total = 0;
                        }


                    } else {
                        row.total = total;
                    }

                } else {
                    row.total = 0;
                }


            },

            formateTime(val) {
                if (val) {
                    val = val.substring(0, 10);
                    return val;
                } else {
                    return val;
                }
            },
            uploadSettleCommissionFile(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                this.$confirm("是否确定导入到成本?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    distinguishCancelAndClose: true,
                    type: "warning",
                    closeOnClickModal: false,
                }).then(()=>{
                    form.append("type","1");
                    postAction(this.url.importCommission, form)
                        .then((res) => {
                            this.btnLoading = true;
                            //这里做上传之后的操作
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initSettleList();
                                this.init();
                            }

                        }).finally(() => {
                        this.btnLoading = false;
                    })
                }).catch((action=>{

                }))


            },

            uploadSettleRecFile(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                this.$confirm("是否确定导入到应收?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    distinguishCancelAndClose: true,
                    type: "warning",
                    closeOnClickModal: false,
                }).then(()=>{
                    form.append("type","4");
                    postAction(this.url.importCommission, form)
                        .then((res) => {
                            this.btnLoading = true;
                            //这里做上传之后的操作
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initSettleList();
                                this.init();
                            }

                        }).finally(() => {
                        this.btnLoading = false;
                    })
                }).catch((action=>{

                }))


            },
            uploadAllSettle(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                this.$confirm("是否确定导入应付+成本+应收?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    distinguishCancelAndClose: true,
                    type: "warning",
                    closeOnClickModal: false,
                }).then(()=>{
                    form.append("type","3");
                    postAction(this.url.importAllSettle, form)
                        .then((res) => {
                            this.btnLoading = true;
                            //这里做上传之后的操作
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initSettleList();
                                this.init();
                            }

                        }).finally(() => {
                        this.btnLoading = false;
                    })
                }).catch((action=>{

                }))


            },
            uploadSettleCommissionAndRecFile(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                this.$confirm("是否确定导入到成本+应收?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    distinguishCancelAndClose: true,
                    type: "warning",
                    closeOnClickModal: false,
                }).then(()=>{
                    form.append("type","3");
                    postAction(this.url.importCommission, form)
                        .then((res) => {
                            this.btnLoading = true;
                            //这里做上传之后的操作
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initSettleList();
                                this.init();
                            }

                        }).finally(() => {
                        this.btnLoading = false;
                    })
                }).catch((action=>{

                }))


            },
            updateFeeType(row, index) {
                console.log('费用的map', this.feeTypeMap)
                if (this.feeTypeMap[row.feeId]) {
                    this.$set(this.form.osOrderSettleList[index], 'feenameUs', this.feeTypeMap[row.feeId]['englishName']);
                    this.$set(this.form.osOrderSettleList[index], 'feename', this.feeTypeMap[row.feeId]['chineseName']);
                    this.$set(this.form.osOrderSettleList[index], 'fileType', this.feeTypeMap[row.feeId]['type']);
                }
            },
            getSettle(scope) {
                let val = scope.row.settleId;
                if (this.proMap[val]) {
                    this.$set(scope.row, 'settlement', this.proMap[val]['chinaseName']);
                    console.log('获取结算对象', scope, this.form.osOrderSettleList);
                }
            },


            handleSelectionChange2(selection) { // 多选
                let arr = []
                this.nameList = []
                this.selectionRows2 = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.nameList.push(selection[i]['feename'])
                    if (selection[i]['locker'] === '0') {

                    }
                    this.selectionRows2.push(selection[i]);

                }

                this.selectedRowKeys2 = [...arr]
                console.log('选中的集合', this.selectionRows2);
            },

            getSummaries2(param) {
                //遍历集合
                this.totalgbp = 0;
                this.totalsgd = 0;
                this.totalusd = 0;
                this.totalncd = 0;
                this.totalaud = 0;
                this.totalvnd = 0;
                this.totalnzd = 0;
                this.totalhkd = 0;
                this.totalcad = 0;
                this.totaleur = 0;
                this.totalinr = 0;
                this.totalcny = 0;
                this.totalkrw = 0;
                this.totalnok = 0;
                this.totaljpy = 0;
                this.totaltwd = 0;
                if (this.form.osOrderSettleList) {
                    this.form.osOrderSettleList.forEach(s => {
                        if (s.containerSettlementId) {
                            // 是柜子平摊过来的费用 不能修改不能变
                            if (param && s.feename === '平摊差异' && (s.price == param || s.quantity == param)) {
                                // 平摊差异的费用不取整 ,必须有那个柜子分摊的id
                                // this.getAllPrice(s);
                            } else if (param && s.feename !== '平摊差异' && (s.price == param || s.quantity == param)) {
                                //不是平摊差异的费用取整
                                // this.getAllPriceLiu2(s);
                            }
                        } else {
                            if (param && (s.price == param || s.quantity == param)) {
                                this.getAllPrice(s);
                            }
                        }

                        if (s) {
                            if (s.moneyType == 'GBP') {
                                this.totalgbp += Number(s.total);
                            }
                            if (s.moneyType == 'SGD') {
                                this.totalsgd += Number(s.total);
                            }
                            if (s.moneyType == 'USD') {
                                this.totalusd += Number(s.total);
                            }
                            if (s.moneyType == 'NCD') {
                                this.totalncd += Number(s.total);
                            }
                            if (s.moneyType == 'AUD') {
                                this.totalaud += Number(s.total);
                            }
                            if (s.moneyType == 'VND') {
                                this.totalvnd += Number(s.total);
                            }
                            if (s.moneyType == 'NZD') {
                                this.totalnzd += Number(s.total);
                            }
                            if (s.moneyType == 'HKD') {
                                this.totalhkd += Number(s.total);
                            }
                            if (s.moneyType == 'CAD') {
                                this.totalcad += Number(s.total);
                            }
                            if (s.moneyType == 'EUR') {
                                this.totaleur += Number(s.total);
                            }
                            if (s.moneyType == 'INR') {
                                this.totalinr += Number(s.total);
                            }
                            if (s.moneyType == 'CNY') {
                                this.totalcny += Number(s.total);
                            }
                            if (s.moneyType == 'KRW') {
                                this.totalkrw += Number(s.total);
                            }
                            if (s.moneyType == 'NOK') {
                                this.totalnok += Number(s.total);
                            }
                            if (s.moneyType == 'JPY') {
                                this.totaljpy += Number(s.total);
                            }
                            if (s.moneyType == 'TWD') {
                                this.totaltwd += Number(s.total);
                            }

                        }

                    })
                }
                this.showSumm();
                // console.log('展示费用总计', this.ss);

            },
            showSumm() {
                this.ss = "总计: ";
                if (this.totalusd != 0) {
                    this.ss += " USD:" + this.totalusd.toFixed(3);
                }
                if (this.totalcny != 0) {
                    this.ss += " CNY:" + this.totalcny.toFixed(3);
                }
                if (this.totalcad != 0) {
                    this.ss += " CAD:" + this.totalcad.toFixed(3);
                }
                if (this.totalaud != 0) {
                    this.ss += " AUD:" + this.totalaud.toFixed(3);
                }
                if (this.totaleur != 0) {
                    this.ss += " EUR:" + this.totaleur.toFixed(3);
                }
                if (this.totalgbp != 0) {
                    this.ss += " GBP:" + this.totalgbp.toFixed(3);
                }
                if (this.totalhkd != 0) {
                    this.ss += " HKD:" + this.totalhkd.toFixed(3);
                }
                if (this.totalinr != 0) {
                    this.ss += " INR:" + this.totalinr.toFixed(3);
                }
                if (this.totaljpy != 0) {
                    this.ss += " JPY:" + this.totaljpy.toFixed(3);
                }
                if (this.totalkrw != 0) {
                    this.ss += " KRW:" + this.totalkrw.toFixed(3);
                }
                if (this.totalncd != 0) {
                    this.ss += " NCD:" + this.totalncd.toFixed(3);
                }
                if (this.totalnok != 0) {
                    this.ss += " NOK:" + this.totalnok.toFixed(3);
                }
                if (this.totalnzd != 0) {
                    this.ss += " NZD:" + this.totalnzd.toFixed(3);
                }
                if (this.totalsgd != 0) {
                    this.ss += " SGD:" + this.totalsgd.toFixed(3);
                }
                if (this.totaltwd != 0) {
                    this.ss += " TWD:" + this.totaltwd.toFixed(3);
                }
                if (this.totalvnd != 0) {
                    this.ss += " VND:" + this.totalvnd.toFixed(3);
                }

            },
            loadmore() {
                this.formData.pageIndex++;
                this.getMorePortlist(this.formData);
            },
            getMorePortlist(v) {
                let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
                this.prolist = this.allSettlelist.filter((item, index, arr) => {
                    return index < num;
                });
            },
            loadmore2() {
                this.formData2.pageIndex++;
                this.getMorePortlist2(this.formData2);
            },
            getMorePortlist2(v) {
                let num = ~~this.formData2.pageIndex * ~~this.formData2.pageSize;
                this.feeTypeList = this.allFeeTypelist.filter((item, index, arr) => {
                    return index < num;
                });
            },
            updateBatch() {
                this.$refs['payref'].validate(valid => {
                    if (valid) {
                        //全选了才判断
                        this.form.osOrderSettleList.forEach(s => {
                            s.type = '1';
                            s.orderNo = this.$route.query.orderNo;
                            if (this.feeTypeMap[s.feeId]) {
                                s.feename = this.feeTypeMap[s.feeId]['chineseName'];
                                s.feecode = this.feeTypeMap[s.feeId]['code'];
                                s.fileType = this.feeTypeMap[s.feeId]['type'];
                            }
                            this.getAllPrice(s);
                        })
                        putAction(this.url.updateBatch + '?orderNo=' + this.$route.query.orderNo, this.form.osOrderSettleList)
                            .then(res => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.initSettleList();
                                    this.init();
                                }
                            })
                        postAction(this.url.syncFee2CommissionByList + '?type=2', this.form.osOrderSettleList)
                            .then(res => {
                            })
                    }
                })

            },
            lock(row) {
                // if (!row.settlement) {
                //     this.$message.warning('请选择结算对象');
                //     return;
                // }
                // if (!row.feename) {
                //     this.$message.warning('请选择费用名');
                //     return;
                // }
                // if (!row.price) {
                //     this.$message.warning('请输入价格');
                //     return;
                // }
                // if (!row.quantity) {
                //     this.$message.warning('请输入数量');
                //     return;
                // }
                // if (!row.moneyType) {
                //     this.$message.warning('请选择币种');
                //     return;
                // }
                if (!row.id) {
                    this.$message.warning('请保存数据');
                    return;
                }
                if ("1" === row.isCloseAccount) {
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                this.$confirm(`确定锁定${row.feename}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lock + "?id=" + row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initBase();
                    })
                }).catch(() => {

                })
            },
            unlock(row) {
                console.log('解锁对象', row);
                // if (!row.settleId) {
                //     this.$message.warning('请选择结算对象');
                //     return;
                // }
                // if (!row.feename) {
                //     this.$message.warning('请选择费用名');
                //     return;
                // }
                // if (!row.price) {
                //     this.$message.warning('请输入价格');
                //     return;
                // }
                // if (!row.quantity) {
                //     this.$message.warning('请输入数量');
                //     return;
                // }
                // if (!row.moneyType) {
                //     this.$message.warning('请选择币种');
                //     return;
                // }
                if (!row.id) {
                    this.$message.warning('请保存数据');
                    return;
                }
                if (['1', '2', '9'].indexOf(row.status) !== -1) {
                    this.$message.warning('费用在财务流程中不允许解锁');
                    return;
                }
                if ("1" === row.isCloseAccount){
                    this.$message.warning('费用已关账不允许操作');
                    return;
                }
                this.$confirm(`确定解锁${row.feename}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.unlock + "?id=" + row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            lockBatch() {
                let isAllDelete = true;
                let warningMsg = '';
                this.selectionRows2.forEach(s=>{
                    if("1" === s.isCloseAccount){
                        warningMsg = '勾选存在已关账的费用不允许锁定';
                        isAllDelete = false
                        return false;
                    }
                })
                if(!isAllDelete){
                    this.$message.warning(warningMsg);
                    return
                }
                this.$confirm(`确定批量锁定${this.nameList.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lockBatch + "?ids=" + this.selectedRowKeys2.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            unlockBatch() {
                let isAllDelete = true;
                let warningMsg = '';
                this.selectionRows2.forEach(s => {
                    if("1" === s.isCloseAccount){
                        warningMsg = '费用已关账不允许解锁';
                        isAllDelete = false
                        return false;
                    }
                    if (['1', '2', '9'].indexOf(s.status) !== -1) {
                        warningMsg = '费用在财务流程中不允许解锁';
                        isAllDelete = false
                        return false;
                    }
                })
                if (!isAllDelete) {
                    this.$message.warning(warningMsg);
                    return
                }

                this.$confirm(`确定批量解锁${this.nameList.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.unlockBatch + "?ids=" + this.selectedRowKeys2.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            lockIsOrder() {
                this.$confirm(`确定锁定${this.order.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lockIsOrder, {orderNo: this.order.orderNo}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                        this.$emit('init',"payModule")
                    })
                }).catch(() => {

                })
            },
            unLockIsOrder() {
                this.$confirm(`确定解锁${this.order.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.unlockIsOrder, {orderNo: this.order.orderNo}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                        this.$emit('init','payModule2')
                    })
                }).catch(() => {

                })
            },
            lockOrder() {
                this.$confirm(`确定锁定${this.$route.query.orderNo}应付?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.lockOrder + "?orderNo=" + this.$route.query.orderNo + "&type=1").then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },
            unlockOrder() {
                let isAllDelete = true;
                let warningMsg = '';
                this.form.osOrderSettleList.forEach(s => {
                    if (['1', '2', '9'].indexOf(s.status) !== -1) {
                        warningMsg = '费用在财务流程中不允许解锁';
                        isAllDelete = false
                        return false;
                    }
                })
                if (!isAllDelete) {
                    this.$message.warning(warningMsg);
                    return
                }

                this.$confirm(`确定解锁${this.$route.query.orderNo}应付?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.unlockOrder + "?orderNo=" + this.$route.query.orderNo + "&type=1").then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initSettleList();
                        this.init();
                    })
                }).catch(() => {

                })
            },


            syncFee2CommissionBySingle(row) {
                if (!row.id) {
                    this.$message.warning('请保存数据');
                    return;
                }
                this.syncFeeDialogVisible = true;
            },

          syncButton(row){
            this.syncFeeDialogVisible = true
            this.syncRow = row;
          },

          syncFeeByType(type) {
            postAction(this.url.manualsyncFee + "?source=3&target=" + type, this.syncRow).then(res => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.initBase();
            })
            this.syncFeeDialogVisible = false;
          },

            handleQuery2() {
                this.goodsloading = true
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,

                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')

                    } else {
                        this.$set(params, 'column', 'createTime')
                        this.$set(params, 'order', 'asc')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                console.log("查询对象", params)

                getAction(this.url.getSettleList, params).then((res) => {
                    if (res.success) {
                        this.tableData = res.result.list;
                        this.order = res.result.osOrder;
                        this.fenzuList = res.result.fenzuList;
                        console.log("表单数据", this.order,this.tableData);
                        this.form.osOrderSettleList = this.tableData;
                        this.getSummaries2('');
                        this.settlementList = res.result.settlementList;
                        //构建结算对象下拉框
                        this.settlementSelectedList = [];
                        for (let i=0;i<this.settlementList.length;i++) {
                          let obj = this.settlementList[i];
                          this.settlementSelectedList.push({value:obj.id,label:obj.chinaseName});
                        }
                        this.proMap = this.listToMap(this.settlementList, 'id');
                        this.hyFeeList = res.result.bdFeeTypeList;
                        this.feeTypeMap = this.listToMap(this.hyFeeList, "id");
                        //构建费用名称下拉框
                        this.hyFeeSelectedList = [];
                        for (let i=0;i<this.hyFeeList.length;i++) {
                          let obj = this.hyFeeList[i];
                          this.hyFeeSelectedList.push({value:obj.id,label:obj.chineseName});
                        }
                        //个数
                        this.tableCount = this.tableData.length;
                    }
                }).finally(() => {
                    this.goodsloading = false
                })
            },
            handleDel() {

            },


            handleReset() {
                this.queryForm = {}
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.queryForm.type = '1';
                this.ipagination.currentPage = 1
                this.handleQuery2();
            },

            initSettleList() {
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.queryForm.type = '1';
                this.handleQuery2();
                this.caculateProfit();
            },
            initButton() {
                const buttons = document.querySelectorAll('.el-button');
                if (this.isUse) {
                    buttons.forEach(b => {
                        b.disabled = this.isUse;
                    })
                }
                console.log('所有的按钮', buttons);
            },
            initBase() {

                this.disableMixinMounted = true;

                console.log('自己查询', this.order);
                this.initSettleList();
                this.init();

            },
            synchronizationCost() {
                 postAction(this.url.batchManualsyncFee + "?source=3&target=2",this.selectionRows2).then(res => {
              this.$message({
                type: 'success',
                message: res.message
              })
               this.initBase();
            })

            }
        },
        created() {
            this.initBase();
        },
      computed : {
        buttonShowList() {
          return {
            'fzddfy':this.$btnIsShow('docpage/modules/PayModule','0','复制订单费用'),
            'plsd':this.$btnIsShow('docpage/modules/PayModule','0','批量锁定'),
            'pljs':this.$btnIsShow('docpage/modules/PayModule','0','批量解锁'),
            'plsc':this.$btnIsShow('docpage/modules/PayModule','0','批量删除'),
            'sdddyf':this.$btnIsShow('docpage/modules/PayModule','0','锁定订单应付'),
            'sddd':this.$btnIsShow('docpage/modules/RecModule','0','锁定订单'),
            'jsdd':this.$btnIsShow('docpage/modules/RecModule','0','解锁订单'),
            'tjfsfy':this.$btnIsShow('docpage/modules/PayModule','0','添加附属费用'),
            'yfdrmbxz':this.$btnIsShow('docpage/modules/PayModule','0','海运应付批量导入模板下载'),
            'plyfdr':this.$btnIsShow('docpage/modules/PayModule','0','批量海运应付导入'),
            'drmbxz':this.$btnIsShow('docpage/modules/CostModule','0','批量导入模板下载'),
            'pldr':this.$btnIsShow('docpage/modules/CostModule','0','批量导入'),
            'pldryscbys':this.$btnIsShow('docpage/modules/PayModule','0','批量导入应付+成本+应收'),
            'js':this.$btnIsShow('docpage/modules/PayModule','1','解锁'),
            'sd':this.$btnIsShow('docpage/modules/PayModule','1','锁定'),
            'tb':this.$btnIsShow('docpage/modules/PayModule','1','同步'),
            'gz':this.$btnIsShow('docpage/modules/PayModule','1','关账'),
            'jsgz':this.$btnIsShow('docpage/modules/PayModule','1','解除关账'),
            'kygsj':this.$btnIsShow('docpage/modules/PayModule','0','是否可以查看预估的数据'),
            'klr':this.$btnIsShow('docpage/modules/PayModule','0','是否可以查看利润'),
            'sc':this.$btnIsShow('docpage/modules/PayModule', '1', '删除'),
            'pltbcb':this.$btnIsShow('docpage/modules/PayModule', '0', '批量同步到成本'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .span-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90px;
        line-height: 100%;
    }

    .margin_5 {
        margin: 10px 20px 5px 0;
    }
</style>
